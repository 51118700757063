import * as React from "react"
import { useState, useEffect } from "react"
import { Link } from "gatsby"
import NavBar from "../components/navbar"
import "../components/login.css"
import { isLoggedIn, getUser, refreshToken } from "../services/auth"

// import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row, Card, Button, CardDeck } from "react-bootstrap"

const BASE_URL = "47.99.220.122:8000"
export default function Items() {
  const [record, setRecord] = useState([])
  useEffect(() => {
    refreshToken()
    if (!isLoggedIn()) return
    const accessToken = getUser().access
    ;(async () => {
      const pollsurl = BASE_URL + "/login/getItems"
      const method = "get"
      const headers = { Authorization: `Bearer ${accessToken}` }
      const response = await fetch(pollsurl, { method, headers })
      if (response.ok) {
        const record_json = await response.json()
        setRecord(record_json)
      } else {
        console.error("could not get the record ")
      }
    })()
  }, [isLoggedIn, setRecord])

  const cardList = data => {
    console.log(typeof data)
    console.log(data)
    return data.map((sdata, index) => (
      <Card
        style={{
          width: "12rem",
          backgroundColor: "transparent",
          border: "0",
        }}
        key={index}
      >
        <Card.Img variant="top" src={`data:image/jpeg;base64,${sdata.image}`} />
        <Card.Body style={{ backgroundColor: "white" }}>
          <Card.Title>{sdata.name}</Card.Title>
          <Card.Text>拥有数量：{sdata.count}</Card.Text>
        </Card.Body>
      </Card>
    ))
  }

  return (
    <div>
      <NavBar />
      <div className="historycenter">
        <h1> 当前拥有物品 </h1>
        <CardDeck>{cardList(record)}</CardDeck>
      </div>
    </div>
  )
}
